<template>
  <div v-if="getLoaded()" class="duck-form form-width-large form-center">
    <NotiBar v-if="msg.show" :type="msg.type" :msg="msg.text" />
    
    <form @submit.prevent="saveForm">

      <!-- <div class="row2 row2-form">
        <div class="col"> -->
          <fieldset class="form-block">
            <div class="h-title">
              <h3>เนื้อหาบทความ</h3>
            </div>
    
            <div class="form-container">
              <div class="input">
                <Label text="หัวข้อเนื้อหา" />
                <input type="text" v-model="contentData.title">
              </div>
              <div class="input">
                <Label text="หัวข้อใน url" />
                <input type="text" v-model="contentData.slug" required>
                <div v-if="getShowPreviewLinkStatus()" class="preview-link">
                  <a :href="getPreviewLink()" title="preview link" target="_blank">
                    <i class="fa fa-link"></i>
                    {{ getPreviewLink() }}
                  </a>
                </div>
              </div>
              
              <div v-if="contentData.section=='testimonial'" class="input row2">
                <div class="col">
                  <Label text="ชื่อช่างผู้รับงาน" />
                  <input type="text" v-model="contentData.meta.constructor_name">
                </div>
                <div class="col">
                  <Label text="ลิ้งค์" />
                  <input type="text" v-model="contentData.meta.url">
                </div>
              </div>
    
              <div class="input">
                <Label text="คำอธิบาย" />
                <textarea v-model="contentData.description"></textarea>
              </div>
              
              <div class="input row3">
                <div class="col">
                  <Label text="สถานะเนื้อหา" />
                  <select v-model="contentData.status">
                    <option value="draft">Draft</option>
                    <option value="publish">Publish</option>
                  </select>
                </div>
                <div class="col">
                  <Label text="วันที่แสดง" />
                  <datepicker v-model.trim="contentData.publish.date" format="yyyy-MM-dd"></datepicker>
                </div>
                <div class="col">
                  <Label text="เวลาที่แสดง" />
                  <select v-model="contentData.publish.hour" class="select-time">
                    <option v-for="i in 24" :key="i">{{ i-1 }}</option>
                  </select>
                  :
                  <select v-model="contentData.publish.min" class="select-time">
                    <option v-for="i in 60" :key="i">{{ i-1 }}</option>
                  </select>
                  :
                  <select v-model="contentData.publish.sec" class="select-time">
                    <option v-for="i in 60" :key="i">{{ i-1 }}</option>
                  </select>
                </div>
              </div>
    
              <div class="row2">
                <div class="col col-thumb">
                  <Label text="รูปภาพประกอบ" />
                  <SingleImageUploader type="thumbnail" section="content" :parentId="contentData.ID" :description="thumenailDescription" />
                </div>
              </div>
              
              <div v-if="showConstProfileUpload" class="input">
                <Label text="ไฟล์ประวัติผู้รับเหมา" />
                <SingleFileUploader type="const_profile" section="content" :parentId="contentData.ID" />
              </div>
    
              <div v-if="hideDetail=='0'" class="input">
                <Label text="รายละเอียด" />
                <TextEditor v-model="contentData.detail" :uploaderKey="contentData.ID" />
              </div>
            </div>
          </fieldset>

          <fieldset v-if="getShowPreviewLinkStatus()" class="form-block">
            <div class="h-title">
              <h3>SEO</h3>
            </div>
    
            <div class="form-container">
              <p class="input-description">* กรณีไม่ได้กรอก ระบบจะดีงข้อมูลด้านบนมาแสดงแทนในระบบหน้าบ้าน</p>
              <div class="input">
                <Label text="Title" />
                <input type="text" v-model="contentData.meta.seo_title">
              </div>
              <div class="input">
                <Label text="Keyword" />
                <input type="text" v-model="contentData.meta.seo_keyword">
              </div>
              <div class="input">
                <Label text="Description" />
                <textarea v-model="contentData.meta.seo_description"></textarea>
              </div>
            </div>
          </fieldset>
          <!-- end: SEO section -->
        <!-- </div> -->
        <!-- end: col left -->
  
        <!-- <div class="col"> -->
    
          <fieldset v-if="getShowCustomBanner()" class="form-block form-banner">
            <div class="h-title">
              <h3>Custom Banner</h3>
            </div>
    
            <div class="form-container">
              <div class="input" data-slug="banner-1">
                <SmallTitle title="Banner 1" />
                <div class="row2">
                  <div class="col">
                    <SingleImageUploader type="banner-1"
                                         section="content" 
                                         :parentId="contentData.ID"
                                         description="รูปขนาด 1,400x900 pixel" />
                  </div>
                  <div class="col">
                    <div class="input">
                      <input type="text" v-model="contentData.meta.banner.b1.title" placeholder="title...">
                      <input type="text" v-model="contentData.meta.banner.b1.url" placeholder="url..." class="mt-5">
                    </div>
                  </div>
                </div>
              </div>
              <!-- end: banner 1 -->
              
              <div class="input" data-slug="banner-2">
                <SmallTitle title="Banner 2" />
                <div class="row2">
                  <div class="col">
                    <SingleImageUploader type="banner-2" 
                                         section="content" 
                                         :parentId="contentData.ID"
                                         description="รูปขนาด 1,400x900 pixel" />
                  </div>
                  <div class="col">
                    <div class="input">
                      <input type="text" v-model="contentData.meta.banner.b2.title" placeholder="title...">
                      <input type="text" v-model="contentData.meta.banner.b2.url" placeholder="url..." class="mt-5">
                    </div>
                  </div>
                </div>
              </div>
              <!-- end: banner 2 -->
              
              <div class="input" data-slug="banner-3">
                <SmallTitle title="Banner 3" />
                <div class="row2">
                  <div class="col">
                    <SingleImageUploader type="banner-3" 
                                         section="content" 
                                         :parentId="contentData.ID"
                                         description="รูปขนาด 1,400x900 pixel" />
                  </div>
                  <div class="col">
                    <div class="input">
                      <input type="text" v-model="contentData.meta.banner.b3.title" placeholder="title...">
                      <input type="text" v-model="contentData.meta.banner.b3.url" placeholder="url..." class="mt-5">
                    </div>
                  </div>
                </div>
              </div>
              <!-- end: banner 3 -->
              
              <div class="input" data-slug="banner-4">
                <SmallTitle title="Banner 4" />
                <div class="row2">
                  <div class="col">
                    <SingleImageUploader type="banner-4" 
                                         section="content" 
                                         :parentId="contentData.ID"
                                         description="รูปขนาด 1,400x900 pixel" />
                  </div>
                  <div class="col">
                    <div class="input">
                      <input type="text" v-model="contentData.meta.banner.b4.title" placeholder="title...">
                      <input type="text" v-model="contentData.meta.banner.b4.url" placeholder="url..." class="mt-5">
                    </div>
                  </div>
                </div>
              </div>
              <!-- end: banner 4 -->
              
              <div class="input" data-slug="banner-5">
                <SmallTitle title="Banner 5" />
                <div class="row2">
                  <div class="col">
                    <SingleImageUploader type="banner-5" 
                                         section="content" 
                                         :parentId="contentData.ID"
                                         description="รูปขนาด 1,400x900 pixel" />
                  </div>
                  <div class="col">
                    <div class="input">
                      <input type="text" v-model="contentData.meta.banner.b5.title" placeholder="title...">
                      <input type="text" v-model="contentData.meta.banner.b5.url" placeholder="url..." class="mt-5">
                    </div>
                  </div>
                </div>
              </div>
              <!-- end: banner 5 -->
              
              <div class="input" data-slug="banner-6">
                <SmallTitle title="Banner 6" />
                <div class="row2">
                  <div class="col">
                    <SingleImageUploader type="banner-6" 
                                         section="content" 
                                         :parentId="contentData.ID"
                                         description="รูปขนาด 1,400x900 pixel" />
                  </div>
                  <div class="col">
                    <div class="input">
                      <input type="text" v-model="contentData.meta.banner.b6.title" placeholder="title...">
                      <input type="text" v-model="contentData.meta.banner.b6.url" placeholder="url..." class="mt-5">
                    </div>
                  </div>
                </div>
              </div>
              <!-- end: banner 6 -->

            </div>
          </fieldset>
        <!-- </div> -->
        <!-- end: col right -->
      <!-- </div> -->
      <!-- end: row -->

      <div class="button-block">
        <button type="submit" class="btn btn-update">
          <i class="fa fa-save fa-lg"></i> บันทึกข้อมูล
        </button>
      </div>
    </form>
  </div>
</template>


<script>
// @ is an alias to /src
import {globalVars} from '@/variables/config.js';
import {apiRequest} from '@/utils/axios/axiosInstance.js';
import moment       from 'moment';

import Datepicker         from 'vuejs-datepicker';
import NotiBar            from '@/components/common/NotiBar.vue';
import Label              from '@/components/common/form/Label.vue';
import TextEditor         from '@/components/common/form/TextEditor.vue';
import SmallTitle         from '@/components/common/SmallTitle.vue';
import SingleImageUploader from '@/components/file/SingleImageUploader.vue';
import SingleFileUploader from '@/components/file/SingleFileUploader.vue';

export default {
  props: {
    pageTitle: {
      type: String,
      default: ''
    },

    hideDetail: {
      type: String,
      default: '0'
    },
    showPreviewLink: {
      type: String,
      default: '0'
    },
    showConstProfileUpload: {
      type: Boolean,
      default: false
    },

    thumenailDescription: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      loaded: false,
      msg: {
        show: false,
        type: 'success',
        text: '',
      },

      contentId: '',
      contentData: {
        publish: {},
      },
      isEditForm: false,
    }
  },
  components: {
    Datepicker,
    Label,
    NotiBar,
    SingleImageUploader,
    SingleFileUploader,
    SmallTitle,
    TextEditor,
  },
  created() {
    this.contentId = this.$route.params.id;
    this.loadPage();
  },
  methods: {
    getLoaded() {
      return !this.$store.state.show_loader;
    },
    loadPage:function() {
      this.$store.commit('setLoaderShow', true);
      apiRequest
        .get('/content/post-item/'+this.contentId)
        .then( (res) => { console.log(res.data);
          if(res.data.status==200) {
            
            this.contentData = res.data.result;
            this.contentData.publish = {
              date: this.contentData.date_publish.substring(0, 10),
              hour: parseInt(this.contentData.date_publish.substring(11, 13)),
              min:  parseInt(this.contentData.date_publish.substring(14 ,16)),
              sec:  parseInt(this.contentData.date_publish.substring(17))
            }

            if(this.contentData.slug===null) {
              this.contentData.slug = '';
            }

            if(this.contentData.status=='new' || this.contentData.status=='trash') {
              this.isEditForm = false;
              this.contentData.status = 'draft';
            }else{
              this.isEditForm = true;
            }
          }
          
          let contentSection = this.contentData.section;

          // if section is 'testimonial' , assign 'constructor_name' to meta data
          if(contentSection=='testimonial' && Object.keys(this.contentData.meta).length==0) {
            this.contentData.meta = {
              constructor_name: '',
              url: ''
            };
          }

          // if section home guru or recommend
          if((contentSection=='homeguru' || contentSection=='recommended') && Object.keys(this.contentData.meta).length==0) {
            this.contentData.meta = {
              seo_title: '',
              seo_description: '',
              seo_keyword: '',
            };
          }

          if(!('banner' in this.contentData.meta)) {
            this.contentData.meta.banner = {
              b1: {title: '', url: ''},
              b2: {title: '', url: ''},
              b3: {title: '', url: ''},
              b4: {title: '', url: ''},
              b5: {title: '', url: ''},
              b6: {title: '', url: ''},
            }
          }
          
          
          this.setPageTitle();
          this.$store.commit('setLoaderShow', false);
        });
    },
    saveForm:function() {
      this.$store.commit('setLoaderShow', true);

      this.contentData.date_publish  = this.dateSaveFormat();
      this.contentData.slug  = this.slug2uri( this.contentData.slug );
      // console.log(this.contentData);

      apiRequest
        .post('/content/save-post', this.contentData)
        .then( (res) => { console.log(res.data);
          this.msg.show = true;
          
          if(res.data.status==200) {
            this.msg.text = 'Save data complete';
            
            // replace new slug
            if(this.contentData.slug != res.data.result.slug) {
              this.contentData.slug = res.data.result.slug;
            }

            // force change this page to Edit Form
            this.isEditForm = true;
            this.setPageTitle();
          }else{
            this.msg.type = 'error';
            this.msg.text = 'Save data fail. Please, try again.';
          }
          this.$store.commit('setLoaderShow', false);
        });
    },

    /**
     * Set page title to 'Add' or 'Edit'
     */
    setPageTitle() {
      if(this.pageTitle!=='') {
        let title = ( this.isEditForm ? 'Edit ':'Add ' ) + this.pageTitle;
        this.$store.commit('setPageTitle', title);
        this.$store.commit('setHeadTitle', title);
      }
    },

    /**
     * get show contom banner status
     */
    getShowCustomBanner() {
      return this.contentData.section == 'homeguru';
    },

    /**
     * get show preview link status
     */
    getShowPreviewLinkStatus() {
      return this.showPreviewLink=='1'; // && this.isEditForm
    },

    /**
     * Get preview for display in frontend
     */
    getPreviewLink() {
      return  globalVars.url.front + 
              this.contentData.section +
              '/view/' +
              this.slug2uri( this.contentData.slug );
              // this.contentData.ID + '/' +
    },

    /**
     * prepare date format
     */
    customFormatter(date) {
      return moment(date).format('YYYY-MM-DD');
    },

    /**
     * date save format
     */
    dateSaveFormat() {
      let publish = this.contentData.publish;
      return  this.customFormatter(publish.date) +' '+
              this.zeroAfter(publish.hour)+':'+
              this.zeroAfter(publish.min)+':'+
              this.zeroAfter(publish.sec);
    },

    /**
     * add '0' if less than 10
     */
    zeroAfter(num) {
      return num < 10 ? '0'+num : num;
    },

    /**
     * Convert slug to url format
     */
    slug2uri(str) {
      str = str.replace(/^\s+|\s+$/g, ''); // trim
      str = str.toLowerCase();
    
      // remove accents, swap ñ for n, etc
      var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to   = "aaaaaeeeeiiiioooouuuunc------";

      for (var i=0, l=from.length ; i<l ; i++) {
          str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i));
      }

      str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
          .replace(/\s+/g, '-') // collapse whitespace and replace by -
          .replace(/-+/g, '-'); // collapse dashes

      return str;
    }
  }
}
</script>

<style scoped>
.col-thumb {padding-right: 15px;}

.preview-link {
  margin-top: 5px;
}
.select-time {
  padding-left: 5px;
  width: 45px;
}

.mt-5 {margin-top: 5px;}
.form-banner .input {padding-bottom: 15px;}
.form-banner .input+.input { 
  padding-top: 25px;
  border-top: solid 2px #000;
}

/* .row2-form .col:first-child {
  padding-right: 20px;
}

@media all and (max-width: 900px) {
  .row2-form .col {
    float: none;
    width: auto;
  }
  .row2-form .col:first-child {
    padding-right: 0;
  }
} */
</style>